<template>
  <div class="container">
    <h4 class="text-center my-4">Календарь праздников</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3">
      <div class="p-2">
        <div v-for="(holiday, index) in holidays" :key="index">
          <div class="row mt-4">
            <label class="col-md-3 col-form-label">{{ holiday.name }}</label>
            <div class="col-md-3">
              <label class="">Начало праздника</label>
              <input type="datetime-local" class="form-control" :value="holiday.start_date"
                     @change="changeHolidayDate('start', index, $event)">
            </div>
            <div class="col-md-3">
              <label class="">Конец праздника</label>
              <input type="datetime-local" class="form-control" :value="holiday.end_date"
                     @change="changeHolidayDate('end', index, $event)">
            </div>
            <!--            <div class="col-md-2">-->
            <!--              <button type="button" class="btn btn-secondary" @click="deleteHoliday(index)">-->
            <!--                X-->
            <!--              </button>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </div>

    <Button class="text-center" @click="updateHolidayCalendar" label="Сохранить"/>

  </div>
</template>

<script>


import {mapActions, mapState, mapMutations} from 'vuex'


export default {
  name: 'HolidayCalendar',
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState('holidayCalendar', ['holidays']),
  },
  methods: {
    ...mapActions('holidayCalendar', ['GET', 'PUT', 'POST', 'DELETE']),
    ...mapMutations('holidayCalendar', ['CHANGE_HOLIDAY']),
    getHolidays() {
      this.GET()
    },
    changeHolidayDate(start_end, index, e) {
      this.CHANGE_HOLIDAY({start_end, index, value: e.target.value})
    },
    updateHolidayCalendar() {
      if(this.PUT()){
        this.$message({title: 'Обновление', text: 'Данные успешно обновились'})
      }
    },
    // deleteHoliday(index){
    //   this.holidays.splice(index, 1)
    //   this.DELETE(this.holidays[index].id)
    // }
  },
  async mounted() {
    await this.getHolidays()
    this.loading = false
  }
}

</script>

<style scoped>

</style>
